import React, {useEffect, useState} from 'react';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import FeatherIcon from "feather-icons-react";
import {blogimg1, blogimg10, blogimg11, blogimg12, blogimg2, blogimg3, blogimg4, blogimg5, blogimg6, blogimg7, blogimg8, blogimg9,  } from '../../imagepath';
import { Link } from 'react-router-dom';

import axios from "axios";

const Articles = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageMax, setPageMax] = useState(10);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    var filtertenantid = params['filtertenantid'];
    var filtertext = '';
    if(filtertenantid != null) {
        filtertext = '';
    }

    useEffect(() => {
        let url = process.env.REACT_APP_API_BASE_URL + "/articles?PageNumber=" + pageNumber + "&PageMax=" + pageMax + "" + filtertext;
        console.log('URL: ' + url)
        axios.get(url, {
          headers: {
            'token': localStorage.getItem('AuthKey')
          }}).then((response) => {
          if(response.status == 200) {
            console.log(JSON.stringify(response));
            if(response.data != null) {
                setData(response.data.data.Posts);
            } else {
                console.log('No data');
            }
          } else {
            console.log('Failed to fetch posts');
          }
        }).catch((err) => {
          console.log('error', err.response.data);
        });
    }, []);

    return (
        <div>
            <div className="main-wrapper">
                <Header />
                <Sidebar id='menu-item11' id1='menu-items11' activeClassName='blog-grid'/>
                <div className="page-wrapper">
                    <div className="content">
                        <div className="page-header">
                            <div className="row">
                                <div className="col-sm-12">
                                    <ul className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link to="/articles">Articles </Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <i className="feather-chevron-right">
                                                <FeatherIcon icon="chevron-right"/>
                                            </i>
                                        </li>
                                        <li className="breadcrumb-item active">Articles</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {data.map((item, index) => (
                                <div className="col-sm-6 col-md-6 col-xl-4">
                                    <div className="blog grid-blog">
                                        <div className="blog-image">
                                            <Link to="/blog">
                                                <img
                                                    className="img-fluid"
                                                    src={blogimg1}
                                                alt="#"
                                                />
                                            </Link>
                                            <div className="blog-views">
                                                <h5>Diabetes</h5>
                                            </div>
                                            <ul className="nav view-blog-list blog-views">
                                                <li>
                                                    <i className="feather-message-square me-1" />
                                                    <FeatherIcon icon ="message-square" />
                                                    58
                                                </li>
                                                <li>
                                                    <i className="feather-eye me-1" />
                                                    <FeatherIcon icon ="eye" />
                                                    500
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="blog-content">
                                            <div className="blog-grp-blk">
                                                <div className="blog-img-blk">
                                                    <Link to="/blog">
                                                        <img
                                                            className="img-fluid"
                                                            src={blogimg2}
                                                        alt="#"
                                                        />
                                                    </Link>
                                                    <div className="content-blk-blog ms-2">
                                                        <h4>
                                                            <Link to="profile.html">Jenifer Robinson</Link>
                                                        </h4>
                                                        <h5>M.B.B.S, Diabetologist</h5>
                                                    </div>
                                                </div>
                                                <span>
                                                    <i className="feather-calendar me-1" />
                                                    05 Sep 2022
                                                </span>
                                            </div>
                                            <h3 className="blog-title">
                                                <Link to="/blog">
                                                    Simple Changes That Lowered My Moms Blood Pressure
                                                </Link>
                                            </h3>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                                eiusmod tempor incididunt ut labore et dolore magna aliqua...
                                            </p>
                                            <Link to="/blog" className="read-more d-flex">
                                                {" "}
                                                Read more in 8 Minutes
                                                <i className="fa fa-long-arrow-right ms-2" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="notification-box">
                        <div className="msg-sidebar notifications msg-noti">
                            <div className="topnav-dropdown-header">
                                <span>Messages</span>
                            </div>
                            <div className="drop-scroll msg-list-scroll" id="msg_list">
                                <ul className="list-box">
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">R</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author">Richard Miles </span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item new-message">
                                                <div className="list-left">
                                                    <span className="avatar">J</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author">John Doe</span>
                                                    <span className="message-time">1 Aug</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">T</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author"> Tarah Shropshire </span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">M</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author">Mike Litorus</span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">C</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author"> Catherine Manseau </span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">D</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author"> Domenic Houston </span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">B</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author"> Buster Wigton </span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">R</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author"> Rolland Webber </span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">C</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author"> Claire Mapes </span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">M</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author">Melita Faucher</span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">J</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author">Jeffery Lalor</span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">L</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author">Loren Gatlin</span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="chat.html">
                                            <div className="list-item">
                                                <div className="list-left">
                                                    <span className="avatar">T</span>
                                                </div>
                                                <div className="list-body">
                                                    <span className="message-author">Tarah Shropshire</span>
                                                    <span className="message-time">12:28 AM</span>
                                                    <div className="clearfix" />
                                                    <span className="message-content">
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing
                                                    </span>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="topnav-dropdown-footer">
                                <Link to="chat.html">See all messages</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Articles;
