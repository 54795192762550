import React from "react";
import {
  login02,
  loginicon01,
  loginicon02,
  loginicon03,
  loginlogo,
} from "../../imagepath";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";

import axios from "axios";
import $, { param } from 'jquery';

const Signup = () => {
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [passwordVisible, setPasswordVisible] = useState(true);
  const [password, setPassword] = useState('');
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(true);
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [consentAgreed, setConsentAgreed] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordConfirmVisibility = () => {
    setPasswordConfirmVisible(!passwordConfirmVisible);
  };

  const toggleConsent = () => {
    setConsentAgreed(!consentAgreed);
  }

  const onSubmit = () => {
    $('#error-container').html('');
    if(email.length == 0) {
      $('#error-container').html('You MUST enter an email address.');
    }
    if(password.length == 0) {
      $('#error-container').html('You MUST enter a password.');
      return;
    }
    if(password != passwordConfirm) {
      $('#error-container').html('Passwords MUST match.');
      return;
    }
    if(!consentAgreed) {
      $('#error-container').html('You MUST agree to our terms of use. Please try again.');
      return;
    }
    var url = 
    axios.post(process.env.REACT_APP_API_BASE_URL + "/signup", {
      Fullname: fullname,
      Email: email,
      Telephone: phone,
      Password: password
    }).then((response) => {
      console.log(JSON.stringify(response));
      if(response.status == 200) {
        localStorage.setItem("User", JSON.stringify(response.data.data.user));
        localStorage.setItem("RoleType", JSON.stringify(response.data.data.roletype));
        localStorage.setItem("AuthKey", response.data.data.key);
        localStorage.setItem("AuthExpires", response.data.data.expires);
        let roletype = response.data.data.roletype;
        window.location = '/patient-dashboard';
        if(roletype.includes('SuperAdmin')) {
          window.location = '/superadmin-dashboard';
        } else if(roletype.includes('TenantAdmin')) {
          window.location = '/admin-dashboard';
        } else if(roletype.includes('Specialist')) {
          window.location = '/specialist-dashboard';
        } else if(roletype.includes('Nurse')) {
          window.location = '/nurse-dashboard';
        } else if(roletype.includes('Receptionist')) {
          window.location = '/receptionist-dashboard';
        } else if(roletype.includes('Patient')) {
          window.location = '/patient-dashboard';
        }
      } else {
        alert('Failed to login. Please try again.');
      }
    }).catch((err) => {
      console.log('error', err.response.data);
      $('#error-container').html('Failed to sign in. ' + err.response.data.data);
    });
  }

  return (
    <div>
      <div className="main-wrapper login-body">
        <div className="container-fluid px-0">
          <div className="row">
            <div className="col-lg-6 login-wrap">
              <div className="login-sec">
                <div className="log-img">
                  <img className="img-fluid" src={login02} alt="Logo" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 login-wrap-bg">
              <div className="login-wrapper">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <div className="account-logo">
                        <Link to="/admin-dashboard">
                          <img src={loginlogo}alt="#"/>
                        </Link>
                      </div>
                      <h3>Ready to take ownership of your health data?</h3>
                      {/*<form action="./login">*/}
                        <div className="form-group" style={{marginTop:'45px'}}>
                          <label>
                            Full Name <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="text" value={fullname} onChange={(e) => setFullname(e.target.value)} />
                        </div>
                        <div className="form-group">
                          <label>
                            Email <span className="login-danger">*</span>
                          </label>
                          <input className="form-control" type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className="form-group">
                          <label>
                            Phone <span className="login-success">(Optional)</span>
                          </label>
                          <input className="form-control" type="text" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div className="form-group">
                          <label>
                            Password <span className="login-danger">*</span>
                          </label>
                          <input
                          type={passwordVisible ? 'password' : ''}
                          className="form-control pass-input"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                        </span>
                        </div>
                        <div className="form-group">
                          <label>
                            Confirm Password{" "}
                            <span className="login-danger">*</span>
                          </label>
                          <input
                          type={passwordConfirmVisible ? 'password' : ''}
                          className="form-control pass-input"
                          value={passwordConfirm}
                          onChange={(e) => setPasswordConfirm(e.target.value)}
                        />
                        <span
                          className="toggle-password"
                          onClick={togglePasswordConfirmVisibility}
                        >
                          {passwordConfirmVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
                        </span>
                          {/* <input
                            className="form-control pass-input-confirm"
                            type="password"
                          /> */}
                          <span className="profile-views feather-eye-off confirm-password" />
                        </div>
                        <div className="forgotpass">
                          <div className="remember-me">
                            <label className="custom_check mr-2 mb-0 d-inline-flex remember-me">
                              {" "}
                              I agree to the{" "}
                              <Link to="/terms">&nbsp; terms of service </Link>
                              &nbsp; and{" "}
                              <Link to="/privacy">&nbsp; privacy policy </Link>
                              <input type="checkbox" name="radio" onChange={toggleConsent} />
                              <span className="checkmark" />
                            </label>
                          </div>
                        </div>
                        <div id="error-container" style={{color:'red',marginBottom:'20px'}}></div>
                        <div className="form-group login-btn">
                          <button
                            onClick={onSubmit}
                            className="btn btn-primary btn-block"
                            type="submit">
                            Sign up
                          </button>
                        </div>
                      {/*</form>*/}
                      <div className="next-sign">
                        <p className="account-subtitle">
                          Already have account? <Link to="/login">Login</Link>
                        </p>
                        {/*<div className="social-login">
                          <Link to="#">
                            <img src={loginicon01}alt="#"/>
                          </Link>
                          <Link to="#">
                            <img src={loginicon02}alt="#"/>
                          </Link>
                          <Link to="#">
                            <img src={loginicon03}alt="#"/>
                          </Link>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
