/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react'
import { pagination, Table } from "antd";
import {onShowSizeChange,itemRender}from  '../Pagination'
import {Link} from 'react-router-dom';
import {useState} from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { imagesend, pdficon, pdficon2, pdficon3, blogimg2,blogimg4,blogimg6,blogimg12,blogimg10,blogimg8,pdficon4, plusicon, refreshicon, searchnormal } from '../imagepath';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';

import axios from "axios";
import $, { param } from 'jquery';

const BannerList = () => {
  const [data, setData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageMax, setPageMax] = useState(10);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  var filtertenantid = params['filtertenantid'];
  var filtertext = '';
  if(filtertenantid != null) {
    filtertext = '';
  }

  useEffect(() => {
    let url = process.env.REACT_APP_API_BASE_URL + "/banners?PageNumber=" + pageNumber + "&PageMax=" + pageMax + "" + filtertext;
    console.log('URL: ' + url);
    axios.get(url, {
      headers: {
        'token': localStorage.getItem('AuthKey')
      }}).then((response) => {
      if(response.status == 200) {
        console.log(JSON.stringify(response));
        setData(response.data.data.Clinics);
      } else {
        console.log('Failed to fetch clinics');
      }
    }).catch((err) => {
      console.log('error', err.response.data);
    });
  }, []);

  const rowSelection = { selectedRowKeys, onChange: onSelectChange };

  const columns = [
    {
      title: "ID",
      dataIndex: "ClinicID"
    },
    {
      title: "Type",
      dataIndex: "TypeTitle"
    },
    {
      title: "Title",
      dataIndex: "Title"
    },
    {
      title: "PhoneA",
      dataIndex: "PhoneA"
    },
    {
      title: "PhoneB",
      dataIndex: "PhoneB"
    },
    {
      title: "Email",
      dataIndex: "Email"
    },
    {
      title: "Website",
      dataIndex: "Website"
    },
    {
      title: "Actions",
      dataIndex: "FIELD8",
      render: (text, record) => (
        <>
          <div className="text-end">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to="/edit-clinic">
                  <i className="far fa-edit me-2" />
                  Edit
                </Link>
                <Link className="dropdown-item" to="#" data-bs-toggle="modal" data-bs-target="#delete_patient">
                  <i className="fa fa-trash-alt m-r-5"></i> Delete
                </Link>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ]

  return (
    <div>
      <Header />
      <Sidebar id='menu-item6' id1='menu-items6' activeClassName='department-list'/>
      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/clinics">Clinics </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Clinic List</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card card-table show-entire">
                  <div className="card-body">
                    <div className="page-table-header mb-2">
                      <div className="row align-items-center">
                        <div className="col">
                          <div className="doctor-table-blk">
                            <h3>Clinic List</h3>
                            <div className="doctor-search-blk">
                              <div className="top-nav-search table-search-blk">
                                <form>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search here"
                                  />
                                  <Link className="btn">
                                    <img
                                      src={searchnormal}
                                      alt="#"
                                    />
                                  </Link>
                                </form>
                              </div>
                              <div className="add-group">
                                <Link
                                  to="/add-department"
                                  className="btn btn-primary add-pluss ms-2">
                                  <img src={plusicon}alt="#" />
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-primary doctor-refresh ms-2">
                                  <img src={refreshicon}alt="#" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto text-end float-end ms-auto download-grp">
                          <Link to="#" className=" me-2">
                            <img src={pdficon}alt="#" />
                          </Link>
                          <Link to="#" className=" me-2">
                            <img src={pdficon2}alt="#" />
                          </Link>
                          <Link to="#" className=" me-2">
                            <img src={pdficon3}alt="#" />
                          </Link>
                          <Link to="#">
                            <img src={pdficon4}alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <Table
                        /*pagination={{
                          total: datasource.length,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          // showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}*/
                        columns={columns}
                        dataSource={data}
                        //rowSelection={rowSelection}
                        //rowKey={(record) => record.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
  <div id="delete_patient" className="modal fade delete-modal" role="dialog">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-body text-center">
          <img src={imagesend}alt="#" width={50} height={46} />
          <h3>Are you sure want to delete this banner?</h3>
          <div className="m-t-20">
            {" "}
           <Link to="#" className="btn btn-white me-2" data-bs-dismiss="modal">
              Close
            </Link>
            <button type="submit" className="btn btn-danger">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default BannerList;
