/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Link } from "react-router-dom";
import TextEditor from '../TextEditor';
import Header from '../Header';
import Sidebar from '../Sidebar';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import Select from "react-select";
import { DatePicker} from "antd";
import { staricon, dep_icon1, dep_icon2, dep_icon3, dep_icon4, dep_icon5, dangericon } from "../imagepath";
import { Wizard, useWizard } from 'react-use-wizard';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ManageLifePod = () => {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [blog, setBlog] = useState([
    { value: 1, label: "Choose Blog Category" },
    { value: 2, label: "Health Care" },
    { value: 3, label: "Child" },
    { value: 4, label: "Safety" },
  ]);
  const[statevalue,setStateValue]=useState([
    { value: 1, label: "Select State" },
    { value: 2, label: "Alaska" },
    { value: 3, label: "California" },
  ]);
  const [options, setOptions] = useState([
    { value: 1, label: "Select City" },
    { value: 2, label: "Alaska" },
    { value: 3, label: "Los Angeles" },
  ]);
  const [category,setCategory ] = useState([
    { value: 1, label: "Choose Sub Blog Category" },
    { value: 2, label: "Health Care" },
    { value: 3, label: "Corona Virus" },
  ]);
  const [option, setOption] = useState([
    { value: 1, label: "Select Country" },
    { value: 2, label: "Usa" },
    { value: 3, label: "Uk" },
    { value: 4, label: "Italy" },
  ]);
  const [department, setDepartment] = useState([
    { value: 1, label: "Orthopedics" },
    { value: 2, label: "Radiology" },
    { value: 3, label: "Dentist" },
  ]);
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };
  const loadFile = (event) => {
      // console.log(event,"event");
  };

  var percentage = 0;

  const Step1 = () => {
    const { handleStep, previousStep, nextStep } = useWizard();

    handleStep(() => {
      percentage = 20;
    });

    previousStep(() => {
      // NO PREVIOUS STEP
    });

    return (
      <>
        <div className="row">
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-3">
                      <div className="form-heading">
                        <div style={{ width: 150, height: 150 }}>
                          <CircularProgressbar 
                            value={percentage}
                            text={`${percentage}%`}
                            styles={buildStyles({
                              rotation: 0.25,
                              strokeLinecap: 'butt',
                              textSize: '16px',
                              pathTransitionDuration: 0.5,
                              pathColor: '#DFC8B9',
                              textColor: '#DFC8B9',
                              trailColor: '#C0C0C0',
                              backgroundColor: '#C0C0C0',
                            })} />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon1} alt="#"/>
                        </div>
                        <div className="departments-list">
                          <h4>Personal Details</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon2} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Personal Health Data</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon3} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Social Status</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon4} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>LifePod Card</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon5} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Sharing</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0" style={{marginTop:10}}>
                        <div className="activity-boxs comman-flex-center">
                          <img src={staricon} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Complete</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-heading">
                        <h4>Personal Details</h4>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          First Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Last Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Phone Number <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="+"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Email <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          defaultValue={localStorage.getItem("Email")}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms calendar-icon">
                        <label>
                          Date Of Birth{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <DatePicker
                          className="form-control datetimepicker"
                          onChange={onChange}
                          suffixIcon={null}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group select-gender">
                        <label className="gen-label">
                          Gender<span className="login-danger">*</span>
                        </label>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name="gender"
                              className="form-check-input"
                              defaultChecked=""
                            />
                            Male
                          </label>
                        </div>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name="gender"
                              className="form-check-input"
                            />
                            Female
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                  <h6>{'The core of the AgeFix ecosystem is the control and decentalization of health data. Our commitment to utmost transparency on the purposes behind our data collection and usage stems from this.'}</h6>
                  <br />
                  <h4><b>{'How we use this data?'}</b></h4>
                  <br />
                  <h6>{'We generate a distinct user profile and LifePodID using your first and last name. In order to assess your medical data based on age, your date of birth is required. In order to identify and import your health data from approved medical facilities, certain personal information is required.'}</h6>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
              <div className="activity-top mb-0" style={{marginTop:10}}>
                  <div className="activity-boxs comman-flex-center">
                    <img src={dangericon} alt="#" />
                  </div>
                  <div className="departments-list">
                    <h6>{'We use industry-leading data processing standards to safeguard and preserve your data. We never give your information to outside parties; instead, we always anonymize it. View our Privacy Policy to find out more about how we manage your information.'}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-primary cancel-form me-2" onClick={() => previousStep()}>
          Previous
        </button>
        <button className="btn btn-primary submit-form me-2" onClick={() => nextStep()}>
          Next
        </button>
      </>
    );
  };

  const Step2 = () => {
    const { handleStep, previousStep, nextStep } = useWizard();
  
    handleStep(() => {
      percentage = 40;
    });

    const previous = () => {
      percentage = 0;
      previousStep();
    };
  
    return (
      <>
        <div className="row">
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-3">
                      <div className="form-heading">
                        <div style={{ width: 150, height: 150 }}>
                          <CircularProgressbar 
                            value={percentage}
                            text={`${percentage}%`}
                            styles={buildStyles({
                              rotation: 0.25,
                              strokeLinecap: 'butt',
                              textSize: '16px',
                              pathTransitionDuration: 0.5,
                              pathColor: '#DFC8B9',
                              textColor: '#DFC8B9',
                              trailColor: '#C0C0C0',
                              backgroundColor: '#C0C0C0',
                            })} />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon1} alt="#"/>
                        </div>
                        <div className="departments-list">
                          <h4>Personal Details</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon2} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Personal Health Data</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon3} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Social Status</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon4} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>LifePod Card</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon5} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Sharing</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0" style={{marginTop:10}}>
                        <div className="activity-boxs comman-flex-center">
                          <img src={staricon} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Complete</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
              <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-heading">
                        <h4>Personal Health Data</h4>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          First Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Last Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Phone Number <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="+"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Email <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          defaultValue={localStorage.getItem("Email")}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms calendar-icon">
                        <label>
                          Date Of Birth{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <DatePicker
                          className="form-control datetimepicker"
                          onChange={onChange}
                          suffixIcon={null}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group select-gender">
                        <label className="gen-label">
                          Gender<span className="login-danger">*</span>
                        </label>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name="gender"
                              className="form-check-input"
                              defaultChecked=""
                            />
                            Male
                          </label>
                        </div>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name="gender"
                              className="form-check-input"
                            />
                            Female
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                  <h6>{'The core of the AgeFix ecosystem is the control and decentalization of health data. Our commitment to utmost transparency on the purposes behind our data collection and usage stems from this.'}</h6>
                  <br />
                  <h4><b>{'How we use this data?'}</b></h4>
                  <br />
                  <h6>{'We generate a distinct user profile and LifePodID using your first and last name. In order to assess your medical data based on age, your date of birth is required. In order to identify and import your health data from approved medical facilities, certain personal information is required.'}</h6>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
              <div className="activity-top mb-0" style={{marginTop:10}}>
                  <div className="activity-boxs comman-flex-center">
                    <img src={dangericon} alt="#" />
                  </div>
                  <div className="departments-list">
                    <h6>{'We use industry-leading data processing standards to safeguard and preserve your data. We never give your information to outside parties; instead, we always anonymize it. View our Privacy Policy to find out more about how we manage your information.'}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-primary cancel-form me-2" onClick={() => previous()}>
          Previous
        </button>
        <button className="btn btn-primary submit-form me-2" onClick={() => nextStep()}>
          Next
        </button>
      </>
    );
  };

  const Step3 = () => {
    const { handleStep, previousStep, nextStep } = useWizard();
  
    handleStep(() => {
      percentage = 60;
    });

    const previous = () => {
      percentage = 20;
      previousStep();
    };
  
    return (
      <>
        <div className="row">
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-3">
                      <div className="form-heading">
                        <div style={{ width: 150, height: 150 }}>
                          <CircularProgressbar 
                            value={percentage}
                            text={`${percentage}%`}
                            styles={buildStyles({
                              rotation: 0.25,
                              strokeLinecap: 'butt',
                              textSize: '16px',
                              pathTransitionDuration: 0.5,
                              pathColor: '#DFC8B9',
                              textColor: '#DFC8B9',
                              trailColor: '#C0C0C0',
                              backgroundColor: '#C0C0C0',
                            })} />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon1} alt="#"/>
                        </div>
                        <div className="departments-list">
                          <h4>Personal Details</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon2} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Personal Health Data</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon3} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Social Status</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon4} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>LifePod Card</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon5} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Sharing</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0" style={{marginTop:10}}>
                        <div className="activity-boxs comman-flex-center">
                          <img src={staricon} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Complete</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
              <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-heading">
                        <h4>Social Status</h4>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          First Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Last Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Phone Number <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="+"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Email <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          defaultValue={localStorage.getItem("Email")}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms calendar-icon">
                        <label>
                          Date Of Birth{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <DatePicker
                          className="form-control datetimepicker"
                          onChange={onChange}
                          suffixIcon={null}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group select-gender">
                        <label className="gen-label">
                          Gender<span className="login-danger">*</span>
                        </label>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name="gender"
                              className="form-check-input"
                              defaultChecked=""
                            />
                            Male
                          </label>
                        </div>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name="gender"
                              className="form-check-input"
                            />
                            Female
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                  <h6>{'The core of the AgeFix ecosystem is the control and decentalization of health data. Our commitment to utmost transparency on the purposes behind our data collection and usage stems from this.'}</h6>
                  <br />
                  <h4><b>{'How we use this data?'}</b></h4>
                  <br />
                  <h6>{'We generate a distinct user profile and LifePodID using your first and last name. In order to assess your medical data based on age, your date of birth is required. In order to identify and import your health data from approved medical facilities, certain personal information is required.'}</h6>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
              <div className="activity-top mb-0" style={{marginTop:10}}>
                  <div className="activity-boxs comman-flex-center">
                    <img src={dangericon} alt="#" />
                  </div>
                  <div className="departments-list">
                    <h6>{'We use industry-leading data processing standards to safeguard and preserve your data. We never give your information to outside parties; instead, we always anonymize it. View our Privacy Policy to find out more about how we manage your information.'}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-primary cancel-form me-2" onClick={() => previous()}>
          Previous
        </button>
        <button className="btn btn-primary submit-form me-2" onClick={() => nextStep()}>
          Next
        </button>
      </>
    );
  };

  const Step4 = () => {
    const { handleStep, previousStep, nextStep } = useWizard();
  
    handleStep(() => {
      percentage = 80;
    });

    const previous = () => {
      percentage = 40;
      previousStep();
    };
  
    return (
      <>
        <div className="row">
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-3">
                      <div className="form-heading">
                        <div style={{ width: 150, height: 150 }}>
                          <CircularProgressbar 
                            value={percentage}
                            text={`${percentage}%`}
                            styles={buildStyles({
                              rotation: 0.25,
                              strokeLinecap: 'butt',
                              textSize: '16px',
                              pathTransitionDuration: 0.5,
                              pathColor: '#DFC8B9',
                              textColor: '#DFC8B9',
                              trailColor: '#C0C0C0',
                              backgroundColor: '#C0C0C0',
                            })} />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon1} alt="#"/>
                        </div>
                        <div className="departments-list">
                          <h4>Personal Details</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon2} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Personal Health Data</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon3} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Social Status</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon4} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>LifePod Card</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon5} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Sharing</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0" style={{marginTop:10}}>
                        <div className="activity-boxs comman-flex-center">
                          <img src={staricon} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Complete</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
              <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-heading">
                        <h4>LifePod Card</h4>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          First Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Last Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Phone Number <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="+"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Email <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          defaultValue={localStorage.getItem("Email")}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms calendar-icon">
                        <label>
                          Date Of Birth{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <DatePicker
                          className="form-control datetimepicker"
                          onChange={onChange}
                          suffixIcon={null}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group select-gender">
                        <label className="gen-label">
                          Gender<span className="login-danger">*</span>
                        </label>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name="gender"
                              className="form-check-input"
                              defaultChecked=""
                            />
                            Male
                          </label>
                        </div>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name="gender"
                              className="form-check-input"
                            />
                            Female
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                  <h6>{'The core of the AgeFix ecosystem is the control and decentalization of health data. Our commitment to utmost transparency on the purposes behind our data collection and usage stems from this.'}</h6>
                  <br />
                  <h4><b>{'How we use this data?'}</b></h4>
                  <br />
                  <h6>{'We generate a distinct user profile and LifePodID using your first and last name. In order to assess your medical data based on age, your date of birth is required. In order to identify and import your health data from approved medical facilities, certain personal information is required.'}</h6>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
              <div className="activity-top mb-0" style={{marginTop:10}}>
                  <div className="activity-boxs comman-flex-center">
                    <img src={dangericon} alt="#" />
                  </div>
                  <div className="departments-list">
                    <h6>{'We use industry-leading data processing standards to safeguard and preserve your data. We never give your information to outside parties; instead, we always anonymize it. View our Privacy Policy to find out more about how we manage your information.'}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-primary cancel-form me-2" onClick={() => previous()}>
          Previous
        </button>
        <button className="btn btn-primary submit-form me-2" onClick={() => nextStep()}>
          Next
        </button>
      </>
    );
  };

  const Step5 = () => {
    const { handleStep, previousStep, nextStep } = useWizard();

    handleStep(() => {
      percentage = 100;
    });

    const previous = () => {
      percentage = 60;
      previousStep();
    };

    return (
      <>
        <div className="row">
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-3">
                      <div className="form-heading">
                        <div style={{ width: 150, height: 150 }}>
                          <CircularProgressbar 
                            value={percentage}
                            text={`${percentage}%`}
                            styles={buildStyles({
                              rotation: 0.25,
                              strokeLinecap: 'butt',
                              textSize: '16px',
                              pathTransitionDuration: 0.5,
                              pathColor: '#DFC8B9',
                              textColor: '#DFC8B9',
                              trailColor: '#C0C0C0',
                              backgroundColor: '#C0C0C0',
                            })} />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon1} alt="#"/>
                        </div>
                        <div className="departments-list">
                          <h4>Personal Details</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon2} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Personal Health Data</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon3} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Social Status</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon4} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>LifePod Card</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon5} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Sharing</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0" style={{marginTop:10}}>
                        <div className="activity-boxs comman-flex-center">
                          <img src={staricon} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Complete</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="card">
              <div className="card-body">
              <form>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-heading">
                        <h4>Sharing</h4>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          First Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Last Name <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue=""
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Phone Number <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue="+"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms">
                        <label>
                          Email <span className="login-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="email"
                          defaultValue={localStorage.getItem("Email")}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group local-forms calendar-icon">
                        <label>
                          Date Of Birth{" "}
                          <span className="login-danger">*</span>
                        </label>
                        <DatePicker
                          className="form-control datetimepicker"
                          onChange={onChange}
                          suffixIcon={null}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                      <div className="form-group select-gender">
                        <label className="gen-label">
                          Gender<span className="login-danger">*</span>
                        </label>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name="gender"
                              className="form-check-input"
                              defaultChecked=""
                            />
                            Male
                          </label>
                        </div>
                        <div className="form-check-inline">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              name="gender"
                              className="form-check-input"
                            />
                            Female
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                  <h6>{'The core of the AgeFix ecosystem is the control and decentalization of health data. Our commitment to utmost transparency on the purposes behind our data collection and usage stems from this.'}</h6>
                  <br />
                  <h4><b>{'How we use this data?'}</b></h4>
                  <br />
                  <h6>{'We generate a distinct user profile and LifePodID using your first and last name. In order to assess your medical data based on age, your date of birth is required. In order to identify and import your health data from approved medical facilities, certain personal information is required.'}</h6>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
              <div className="activity-top mb-0" style={{marginTop:10}}>
                  <div className="activity-boxs comman-flex-center">
                    <img src={dangericon} alt="#" />
                  </div>
                  <div className="departments-list">
                    <h6>{'We use industry-leading data processing standards to safeguard and preserve your data. We never give your information to outside parties; instead, we always anonymize it. View our Privacy Policy to find out more about how we manage your information.'}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-primary cancel-form me-2" onClick={() => previous()}>
          Previous
        </button>
        <button className="btn btn-primary submit-form me-2" onClick={() => nextStep()}>
          Next
        </button>
      </>
    );
  };

  const Step6 = () => {
    const { handleStep, previousStep, nextStep } = useWizard();

    handleStep(() => {
      
    });

    const previous = () => {
      percentage = 80;
      previousStep();
    };

    return (
      <>
        <div className="row">
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-3">
                      <div className="form-heading">
                        <div style={{ width: 150, height: 150 }}>
                          <CircularProgressbar 
                            value={percentage}
                            text={`${percentage}%`}
                            styles={buildStyles({
                              rotation: 0.25,
                              strokeLinecap: 'butt',
                              textSize: '16px',
                              pathTransitionDuration: 0.5,
                              pathColor: '#DFC8B9',
                              textColor: '#DFC8B9',
                              trailColor: '#C0C0C0',
                              backgroundColor: '#C0C0C0',
                            })} />
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon1} alt="#"/>
                        </div>
                        <div className="departments-list">
                          <h4>Personal Details</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon2} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Personal Health Data</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon3} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Social Status</h4>
                        </div>
                      </div>
                      <div className="activity-top">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon4} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>LifePod Card</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0">
                        <div className="activity-boxs comman-flex-center">
                          <img src={dep_icon5} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Sharing</h4>
                        </div>
                      </div>
                      <div className="activity-top mb-0" style={{marginTop:10}}>
                        <div className="activity-boxs comman-flex-center">
                          <img src={staricon} alt="#" />
                        </div>
                        <div className="departments-list">
                          <h4>Complete</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                  <h6>{'The core of the AgeFix ecosystem is the control and decentalization of health data. Our commitment to utmost transparency on the purposes behind our data collection and usage stems from this.'}</h6>
                  <br />
                  <h4><b>{'How we use this data?'}</b></h4>
                  <br />
                  <h6>{'We generate a distinct user profile and LifePodID using your first and last name. In order to assess your medical data based on age, your date of birth is required. In order to identify and import your health data from approved medical facilities, certain personal information is required.'}</h6>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
              <div className="activity-top mb-0" style={{marginTop:10}}>
                  <div className="activity-boxs comman-flex-center">
                    <img src={dangericon} alt="#" />
                  </div>
                  <div className="departments-list">
                    <h6>{'We use industry-leading data processing standards to safeguard and preserve your data. We never give your information to outside parties; instead, we always anonymize it. View our Privacy Policy to find out more about how we manage your information.'}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                  {'We use this information for.....'}
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-primary cancel-form me-2" onClick={() => previous()}>
          Previous
        </button>
        <button className="btn btn-primary submit-form me-2" onClick={() => nextStep()}>
          Next
        </button>
      </>
    );
  };

  return (
    <>
      <div className="main-wrapper">
        <Header />
        <Sidebar id='menu-item11' id1='menu-items11' activeClassName='add-blog'/>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/patient-dashboard">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Manage Your LifePod</li>
                  </ul>
                </div>
              </div>
            </div>
            <Wizard>
              <Step1 />
              <Step2 />
              <Step3 />
              <Step4 />
              <Step5 />
              <Step6 />
            </Wizard>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageLifePod
