/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { 
  blog, 
  dashboard, 
  specialist, 
  specialistschedule, 
  logout, 
  menuicon04, 
  menuicon06, 
  menuicon08, 
  menuicon09, 
  menuicon10, 
  menuicon11, 
  menuicon12, 
  menuicon14, 
  menuicon15, 
  menuicon16, 
  patients, 
  sidemenu 
} from '../imagepath';
import Scrollbars from "react-custom-scrollbars-2";

const SuperAdminMenu = (props) => {
  const [sidebar, setSidebar] = useState("");
  const handleClick = (e, item, item1, item3) => {
    const div = document.querySelector(`#${item}`);
    const ulDiv = document.querySelector(`.${item1}`);
    e?.target?.className ? ulDiv.style.display = 'none' : ulDiv.style.display = 'block'
    e?.target?.className ? div.classList.remove('subdrop') : div.classList.add('subdrop');
  }

  useEffect(() => {
    if (props?.id && props?.id1) {
      const ele = document.getElementById(`${props?.id}`);
      handleClick(ele, props?.id, props?.id1);
    }
  }, [])

  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };

  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };

  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu"
              onMouseLeave={expandMenu}
              onMouseOver={expandMenuOpen}>
              <ul>
                <li className="menu-title">Main</li>
                <li>
                  <Link className={props?.activeClassName === 'dashboard' ? 'active' : ''} to="/superadmin-dashboard">
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>{" "}
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item-tenants" onClick={(e) => handleClick(e, "menu-item-tenants", "menu-items-tenants")}>
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>{" "}
                    <span> Tenants</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items-tenants">
                    <li>
                      <Link className={props?.activeClassName === 'add-tenant' ? 'active' : ''} to="/add-tenant">Add Tenant</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'tenants' ? 'active' : ''} to="/tenants">All Tenants</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'tenant-types' ? 'active' : ''} to="/tenant-types">Tenant Types</Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item-clinics" onClick={(e) => handleClick(e, "menu-item-clinics", "menu-items-clinics")}>
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>{" "}
                    <span> Clinics</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items-clinics">
                    <li>
                      <Link className={props?.activeClassName === 'add-clinic' ? 'active' : ''} to="/add-clinic">Add Clinic</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'clinics' ? 'active' : ''} to="/clinics">All Clinics</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'clinic-types' ? 'active' : ''} to="/clinic-types">Clinic Types</Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item-specialists" onClick={(e) => handleClick(e, "menu-item-specialists", "menu-items-specialists")}>
                    <span className="menu-side">
                      <img src={specialist} alt="" />
                    </span>{" "}
                    <span> Specialists</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items-specialists">
                    <li>
                      <Link className={props?.activeClassName === 'add-specialist' ? 'active' : ''} to="/add-specialist">Add Specialist</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'specialists' ? 'active' : ''} to="/specialists">All Specialists</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'specialist-types' ? 'active' : ''} to="/specialist-types">Specialist Types</Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item-patients" onClick={(e) => handleClick(e, "menu-item-patients", "menu-items-patients")}>
                    <span className="menu-side">
                      <img src={patients} alt="" />
                    </span>{" "}
                    <span> Patients</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items-patients">
                    <li>
                      <Link className={props?.activeClassName === 'add-patient' ? 'active' : ''} to="/add-patient">Add Patient</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'patients' ? 'active' : ''} to="/patients">All Patients</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'patient-types' ? 'active' : ''} to="/patient-types">Patient Types</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'staff' ? 'active' : ''} to="/staff">
                    <span className="menu-side">
                      <img src={menuicon08} alt="" />
                    </span>{" "}
                    <span>All Staff</span>
                  </Link>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'appointments' ? 'active' : ''} to="/appointments">
                    <span className="menu-side">
                      <img src={menuicon04} alt="" />
                    </span>{" "}
                    <span>All Appointments</span>
                  </Link>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'operations' ? 'active' : ''} to="/operations">
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>{" "}
                    <span>All Operations</span>
                  </Link>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'departments' ? 'active' : ''} to="/departments">
                    <span className="menu-side">
                      <img src={menuicon08} alt="" />
                    </span>{" "}
                    <span>All Departments</span>
                  </Link>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item11" onClick={(e) => handleClick(e, "menu-item11", "menu-items11")}>
                    <span className="menu-side">
                      <img src={menuicon08} alt="" />
                    </span>{" "}
                    <span> Mobile Banners</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items11">
                  <li>
                      <Link className={props?.activeClassName === 'add-mobile-banner' ? 'active' : ''} to="/add-article">Add Mobile Banner</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'mobile-banners' ? 'active' : ''} to="/mobile-banners">All Mobile Banners</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'banner-targets' ? 'active' : ''} to="/banner-targets">Banner Targets</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'invoices' ? 'active' : ''} to="/invoices">
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>{" "}
                    <span>All Invoices</span>
                  </Link>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'chat' ? 'active' : ''} to="/chat">
                    <span className="menu-side">
                      <img src={menuicon10} alt="" />
                    </span>{" "}
                    <span>Chat</span>
                  </Link>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item9" onClick={(e) => handleClick(e, "menu-item9", "menu-items9")}>
                    <span className="menu-side">
                      <img src={menuicon11} alt="" />
                    </span>{" "}
                    <span> Calls</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items9">
                    <li>
                      <Link className={props?.activeClassName === 'voice-call' ? 'active' : ''} to="/voice-call">Voice Call</Link>
                    </li>
                    <li >
                      <Link className={props?.activeClassName === 'video-call' ? 'active' : ''} to="/video-call">Video Call</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'incoming-call' ? 'active' : ''} to="/incoming-call">Incoming Call</Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item11" onClick={(e) => handleClick(e, "menu-item11", "menu-items11")}>
                    <span className="menu-side">
                      <img src={blog} alt="" />
                    </span>{" "}
                    <span> Articles</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items11">
                  <li>
                      <Link className={props?.activeClassName === 'add-article' ? 'active' : ''} to="/add-article">Add Article</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'articles' ? 'active' : ''} to="/articles">All Articles</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'article-categories' ? 'active' : ''} to="/article-categories">Article Categories</Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item11" onClick={(e) => handleClick(e, "menu-item11", "menu-items11")}>
                    <span className="menu-side">
                      <img src={blog} alt="" />
                    </span>{" "}
                    <span> Reels</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items11">
                  <li>
                      <Link className={props?.activeClassName === 'add-reel' ? 'active' : ''} to="/add-reel">Add Reel</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'reels' ? 'active' : ''} to="/articles">All Reels</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'reel-categories' ? 'active' : ''} to="/reel-categories">Reel Categories</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'assests' ? 'active' : ''} to="/assets">
                    <i className="fa fa-cube" /> <span>Assets</span>
                  </Link>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'activity' ? 'active' : ''} to="/all-activity">
                    <span className="menu-side">
                      <img src={menuicon14} alt="" />
                    </span>{" "}
                    <span>Activity</span>
                  </Link>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'activity' ? 'active' : ''} to="/reports">
                    <span className="menu-side">
                      <img src={menuicon14} alt="" />
                    </span>{" "}
                    <span>Reports</span>
                  </Link>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item12" onClick={(e) => handleClick(e, "menu-item12", "menu-items12")}>
                    <i className="fa fa-flag" /> <span> Reports </span>{" "}
                    <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items12">
                    <li>
                      <Link className={props?.activeClassName === 'expenses-report' ? 'active' : ''} to="/expense-Report"> Expense Report </Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'invoice-report' ? 'active' : ''} to="/invoice-report"> Invoice Report </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/settings">
                    <span className="menu-side">
                      <img src={menuicon16} alt="" />
                    </span>{" "}
                    <span>Settings</span>
                  </Link>
                </li>
              </ul>
              <div className="logout-btn">
                <Link to="/login">
                  <span className="menu-side">
                    <img src={logout} alt="" />
                  </span>{" "}
                  <span>Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  )
}

export default SuperAdminMenu
