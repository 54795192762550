/* eslint-disable no-unused-vars */
import React, {useState,useEffect} from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'
import { DatePicker} from 'antd';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { Link } from 'react-router-dom';
import Select from "react-select";

import axios from "axios";
import $, { param } from 'jquery';

const EditTenant = () => {
  const [data, setData] = useState([]);
  const [tenantTypes, setTenantTypes] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const onChange = (date, dateString) => {
    // console.log(date, dateString);
  };

  const onCancel = () => {
    window.location = '/tenants';
  }

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  var uniqueid = params['uniqueid'];

  useEffect(() => {
    let url = process.env.REACT_APP_API_BASE_URL + "/tenanttypes";
    console.log('URL: ' + url)
    axios.get(url, {
      headers: {
        'token': localStorage.getItem('AuthKey')
      }}).then((response) => {
      if(response.status == 200) {
        console.log(JSON.stringify(response));
        setTenantTypes(response.data.data.TenantTypes);
        let url = process.env.REACT_APP_API_BASE_URL + "/tenants/" + uniqueid;
        console.log('URL: ' + url);
        axios.get(url, {
          headers: {
            'token': localStorage.getItem('AuthKey')
          }}).then((response) => {
          if(response.status == 200) {
            console.log(JSON.stringify(response));
            setData(response.data.data);
            setSelectedOption(data.TenantTypeID);
          } else {
            console.log('Failed');
          }
        }).catch((err) => {
          console.log('error', err.response.data);
        });
      } else {
        console.log('Failed to fetch tenant types');
      }
    }).catch((err) => {
      console.log('error', err.response.data);
    });
  }, []);

  return (
    <div>
      <Header />
      <Sidebar id='menu-item6' id1='menu-items6' activeClassName='add-department'/>
      <>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/tenants">Tenants </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right"/>
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Tenant Detail</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-heading">
                            <h4>Tenant Detail</h4>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Tenant Name <span className="login-danger">*</span>
                            </label>
                            <input className="form-control" type="text" value={data.Title} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6" style={{zIndex:10000}}>
                          <div className="form-group mb-0">
                            <Select
                              className="custom-react-select"
                              defaultValue={selectedOption}
                              onChange={setSelectedOption}
                              options={tenantTypes}
                              placeholder={'Select Type'}
                              id="search-commodity"
                              components={{
                                IndicatorSeparator: () => null
                              }}
                              styles={{
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: state.isFocused ?'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                  boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                  '&:hover': {
                                    borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                  },
                                  borderRadius: '10px',
                                  fontSize: "14px",
                                    minHeight: "45px",
                                }),
                                dropdownIndicator: (base, state) => ({
                                  ...base,
                                  transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                  transition: '250ms',
                                  width: '35px',
                                  height: '35px',
                                }),
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Phone A <span className="login-danger">*</span>
                            </label>
                            <input className="form-control" type="text" value={data.PhoneA} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Phone B <span className="login-primary">(Optional)</span>
                            </label>
                            <input className="form-control" type="text" value={data.PhoneB} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Email <span className="login-danger">*</span>
                            </label>
                            <input className="form-control" type="text" value={data.Email} />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group local-forms">
                            <label>
                              Website <span className="login-primary">(Optional)</span>
                            </label>
                            <input className="form-control" type="text" value={data.Website} />
                          </div>
                        </div>
                        <div className="col-12 col-sm-12">
                          <div className="form-group local-forms">
                            <label>
                              Address <span className="login-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              rows={3}
                              cols={30}
                              defaultValue={""}
                              value={data.FullAddress}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-6">
                          <div className="form-group select-gender">
                            <label className="gen-label">
                              Status <span className="login-danger">*</span>
                            </label>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  className="form-check-input"
                                  checked
                                />
                                Active
                              </label>
                            </div>
                            <div className="form-check-inline">
                              <label className="form-check-label">
                                <input
                                  type="radio"
                                  name="gender"
                                  className="form-check-input"
                                />
                                In Active
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="doctor-submit text-end">
                            <button
                              type="submit"
                              className="btn btn-primary submit-form me-2"
                            >
                              Update Tenant
                            </button>
                            <button
                              onClick={onCancel}
                              type="submit"
                              className="btn btn-primary cancel-form">
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default EditTenant;