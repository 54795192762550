import SuperAdminMenu from './SidebarMenu/SuperAdminMenu';
import AdminMenu from './SidebarMenu/AdminMenu';
import SpecialistMenu from './SidebarMenu/SpecialistMenu';
import ReceptionistMenu from './SidebarMenu/ReceptionistMenu';
import PatientMenu from './SidebarMenu/PatientMenu';

const Sidebar = (props) => {
  let roletype = localStorage.getItem('RoleType');
  console.log('roletype: ' + roletype);
  if(roletype != null) {
    if(roletype.includes('SuperAdmin')) {
      return (
        <SuperAdminMenu />
      );
    } else if(roletype.includes('Admin')) {
      return (
        <AdminMenu />
      );
    } else if(roletype.includes('Specialist')) {
      return (
        <SpecialistMenu />
      );
    } else if(roletype.includes('Nurse')) {
      return (
        <NurseMenu />
      );
    } else if(roletype.includes('Receptionist')) {
      return (
        <ReceptionistMenu />
      );
    } else if(roletype.includes('Patient')) {
      return (
        <PatientMenu />
      );
    }
  }
}

export default Sidebar
