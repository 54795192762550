/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState,useEffect } from 'react'
import { Link } from "react-router-dom";
import TextEditor from '../../TextEditor';
import Header from '../../Header';
import Sidebar from '../../Sidebar';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import Select from "react-select";

import axios from "axios";
import * as AWS from 'aws-sdk';

const s3 = new AWS.S3({
  endpoint: "fra1.digitaloceanspaces.com",
  region: "fra1",
  sslEnabled: true,
  s3ForcePathStyle: false,
  credentials: new AWS.Credentials({
    accessKeyId: "DO00RC2DQ8TZZPTQL8G3",
    secretAccessKey: "4IlI55D293RQ5dlgyRuY4zhl4p6qTwUzam+w/SZGeH0",
  }),
  //http_continue_timeout: 0
});

const generateRandomString = (myLength) => {
  const chars = "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890";
  const randomArray = Array.from({ length: myLength },(v, k) => chars[Math.floor(Math.random() * chars.length)]);
  const randomString = randomArray.join("");
  return randomString;
};

export async function uploadFileToObjectStorage(base64Data, path, fileName, mimeType) {
  const params = {
    Bucket: "agefix-storage-dev",
    Key: `${path}/${fileName}`,
    SourceFile: `${fileName}`,
    Body: base64Data,
    ACL: 'public-read',
    ContentEncoding: 'base64',
    ContentType: mimeType,
  };
  // see: http://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#upload-property
  const { Location } = await s3.upload(params).promise();
  return Location;
}

const AddReel = () => {
  const loadFile = (e) => {
    console.log('Reached..');
    const imageBody = e.target.files[0];
    console.log('Image: ' + imageBody);
    console.log('Uploading Post Image...');
    var mediaid = generateRandomString(24);
    var filetype = 'png';
    //setIsUploading(true);
    uploadFileToObjectStorage(imageBody, 'reels', mediaid + '.' + filetype, 'application').then(function(location) {
      console.log('Upload Location: ' + location);
      setImageSource(location);
    });
  };

  const [categoryOptions,setCategoryOptions] = useState([
    { value: 1, label: "Choose Sub Blog Category" },
    { value: 2, label: "Health Care" },
    { value: 3, label: "Corona Virus" },
  ]);

  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [hashTags, setHashTags] = useState('');
  const [isActive, setIsActive] = useState(1);
  const [content, setContent] = useState('');
  const [mediaURL, setMediaURL] = useState('');

  const [imageSource, setImageSource] = useState('');

  useEffect(() => {
    let url = process.env.REACT_APP_API_BASE_URL + "/reelcategories?PageMax=50000";
    console.log('URL: ' + url);
    axios.get(url, {
      headers: {
        'token': localStorage.getItem('AuthKey')
      }}).then((response) => {
      if(response.status == 200) {
        console.log(JSON.stringify(response));
        if(response.data != null) {
            setData(response.data.data.Posts);
        } else {
            console.log('No data');
        }
      } else {
        console.log('Failed to fetch reel categories');
      }
    }).catch((err) => {
      console.log('error', err.response.data);
    });
  }, []);

  const onSubmit = () => {
    console.log('Submitting...');
    axios.post(process.env.REACT_APP_API_BASE_URL + "/reels", {
      Title: title,
      Author: author,
      Category: selectedCategory,
      SubCategory: selectedSubCategory,
      HashTags: hashTags,
      IsActive: isActive,
      Conent: content,
      MediaURL: mediaURL
    }).then((response) => {
      console.log('response: ' + JSON.stringify(response));
      if(response.status == 200) {
        window.location = '/articles';
      } else {
        console.log('Failed..');
      }
    }).catch((err) => {
      console.log('error', err.response.data);
    });
  }

  return (
    <>
      <div className="main-wrapper">
        <Header />
        <Sidebar id='menu-item11' id1='menu-items11' activeClassName='add-blog'/>
        <>
          <div className="page-wrapper">
              <div className="content">
                <div className="page-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/reels">Reels </Link>
                        </li>
                        <li className="breadcrumb-item">
                          <i className="feather-chevron-right">
                            <FeatherIcon icon="chevron-right"/>
                            </i>
                        </li>
                        <li className="breadcrumb-item active">Add Reel</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        {/*<form>*/}
                          <div className="row">
                            <div className="col-12">
                              <div className="form-heading">
                                <h4>Reel Details</h4>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Reel Title <span className="login-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  value={title}
                                  onChange={setTitle}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Author Name <span className="login-danger">*</span>
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder=""
                                  value={author}
                                  onChange={setAuthor}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                            <div className="form-group local-forms">
                                <label>
                                  Article Category <span className="login-danger">*</span>
                                </label>
                                <Select
                                  className="custom-react-select"
                                  defaultValue={selectedCategory}
                                  onChange={setSelectedCategory}
                                  menuPortalTarget={document.body}
                                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                  options={categoryOptions}
                                  id="search-commodity"
                                  components={{
                                    IndicatorSeparator: () => null
                                  }}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused ?'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                      boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                      '&:hover': {
                                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                      },
                                      borderRadius: '10px',
                                      fontSize: "14px",
                                        minHeight: "45px",
                                    }),
                                    dropdownIndicator: (base, state) => ({
                                      ...base,
                                      transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                      transition: '250ms',
                                      width: '35px',
                                      height: '35px',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Reel Sub Category{" "}
                                  <span className="login-danger">*</span>
                                </label>
                                <Select
                                  className="custom-react-select"
                                  defaultValue={selectedSubCategory}
                                  onChange={setSelectedSubCategory}
                                  menuPortalTarget={document.body}
                                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                  options={categoryOptions}
                                  id="search-commodity"
                                  components={{
                                    IndicatorSeparator: () => null
                                  }}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      borderColor: state.isFocused ?'none' : '2px solid rgba(46, 55, 164, 0.1);',
                                      boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
                                      '&:hover': {
                                        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
                                      },
                                      borderRadius: '10px',
                                      fontSize: "14px",
                                        minHeight: "45px",
                                    }),
                                    dropdownIndicator: (base, state) => ({
                                      ...base,
                                      transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                      transition: '250ms',
                                      width: '35px',
                                      height: '35px',
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group local-forms">
                                <label>
                                  Tags <small>(separated with a comma)</small>{" "}
                                  <span className="login-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  data-role="tagsinput"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-6">
                              <div className="form-group select-gender">
                                <label className="gen-label">
                                  Reel Status <span className="login-danger">*</span>
                                </label>
                                <div className="form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="radio"
                                      name="gender"
                                      className="form-check-input"
                                    />
                                    Active
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <label className="form-check-label">
                                    <input
                                      type="radio"
                                      name="gender"
                                      className="form-check-input"
                                    />
                                    In Active
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                              <div className="form-group summer-mail">
                                <TextEditor
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    console.log({ event, editor, data });
                                    setContent(data);
                                  }}
                                 />
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-12">
                            <img src={imageSource} style={{maxWidth:200}} />
                              <div className="form-group local-top-form">
                                <label className="local-top">
                                  Main Image or Video <span className="login-danger">*</span>
                                </label>
                                <div className="settings-btn upload-files-avator">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="image"
                                  id="file"
                                  onChange={loadFile}
                                  className="hide-input"
                                />
                                <label htmlFor="file" className="upload">
                                Choose File
                                </label>
                            </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="doctor-submit text-end">
                                <button
                                  onClick={onSubmit}
                                  type="submit"
                                  className="btn btn-primary submit-form me-2">
                                  Publish Article
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary cancel-form">
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        {/*</form>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div id="delete_article" className="modal fade delete-modal" role="dialog">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <img src="assets/img/sent.png" alt="" width={50} height={46} />
                    <h3>Are you sure want to delete this reel?</h3>
                    <div className="m-t-20">
                      {" "}
                      <Link to="#" className="btn btn-white" data-bs-dismiss="modal">
                        Close
                      </Link>
                      <button type="submit" className="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
      <div className="sidebar-overlay" data-reff="" />
    </>
  )
}

export default AddReel
