import React from "react";
// eslint-disable-next-line no-unused-vars

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Pages/Login";
// HEALTH POD
import ManageLifePod from "./components/LifePod/ManageLifePod";
import LifePodAccess from "./components/LifePod/LifePodAccess";
// BANNERS
import AddBanner from "./components/Banners/AddBanner";
import EditBanner from "./components/Banners/EditBanner";
import BannerList from "./components/Banners/BannerList";
// import config from "config";
import AddArticle from "./components/Pages/Articles/AddArticle";
import EditArticle from "./components/Pages/Articles/EditArticle";
import Articles from "./components/Pages/Articles/Articles";
import ArticleDetail from "./components/Pages/Articles/ArticleDetail";
// REELS
import AddReel from "./components/Pages/Reels/AddReel";
import EditReel from "./components/Pages/Reels/EditReel";
import Reels from "./components/Pages/Reels/Reels";
import ReelDetail from "./components/Pages/Reels/ReelDetail";
//For Settings...
// import Settings from "./components/settings/Settings";
import Localization from "./components/Settings/Localization";
import Paymentsetting from "./components/Settings/Paymentsetting";
import Settingsemail from "./components/Settings/Settingsemail";
import Settingssocialmedia from "./components/Settings/Settingssocialmedia";
import Settingssociallinks from "./components/Settings/Settingssociallinks";
import Settingsseo from "./components/Settings/Settingsseo";
import SettingsThem from "./components/Settings/SettingsThem";
import SettingsChangePassword from "./components/Settings/SettingsChangePassword";
import SettingsOthers from "./components/Settings/SettingsOthers";
//Asset
import Assets from "./components/Assets/Assets";
import AddAsset from "./components/Assets/AddAsset";
//Tenants
import TenantList from "./components/Tenants/TenantList";
import AddTenant from "./components/Tenants/AddTenant";
import EditTenant from "./components/Tenants/EditTenant";
import TenantTypes from "./components/Tenants/TenantTypes";
//Clinics
import ClinicList from "./components/Clinics/ClinicList";
import AddClinic from "./components/Clinics/AddClinic";
import EditClinic from "./components/Clinics/EditClinic";
//Specialists
import SpecialistList from "./components/Specialist/SpecialistList";
import AddSpecialist from "./components/Specialist/AddSpecialist";
import EditSpecialist from "./components/Specialist/EditSpecialist";
import SpecialistProfile from "./components/Specialist/SpecialistProfile";
//SpecialistSchedule
import ScheduleList from "./components/SpecialistSchedule/ScheduleList";
import AddSchedule from "./components/SpecialistSchedule/AddSchedule";
import EditSchedule from "./components/SpecialistSchedule/EditSchedule";
//Patients
import PatientsList from "./components/Patients/PatientsList";
import AddPatients from "./components/Patients/AddPatients";
import EditPatients from "./components/Patients/EditPatients";
import PatientsProfile from "./components/Patients/PatientsProfile";
//Appointments
import AppointmentList from "./components/Appointments/AppointmentList";
import AddAppointment from "./components/Appointments/AddAppointment";
import EditAppointment from "./components/Appointments/EditAppointment";
//Operations
import OperationList from "./components/Operations/OperationList";
import AddOperation from "./components/Operations/AddOperation";
import EditOperation from "./components/Operations/EditOperation";
//Departments
import DepartmentList from "./components/Department/DepartmentList";
import AddDepartment from "./components/Department/AddDepartment";
import EditDepartment from "./components/Department/EditDepartment";
import StaffList from "./components/Staff/StafList";
import AddStaff from "./components/Staff/Add-Staff";
import StaffProfile from "./components/Staff/StaffProfile";
import ProvidentFund from "./components/Accounts/ProvidentFund";
import ForgotPassword from "./components/Pages/Login/ForgotPassword";
import Signup from "./components/Pages/Login/Signup";
import Invoice from "./components/Accounts/Invoice";
import Create_Invoice from "./components/Accounts/Create_Invoice";
import Payments from "./components/Accounts/Payments";
import Add_Payment from "./components/Accounts/Add_Payment";
import Expenses from "./components/Accounts/Expenses";
import Add_Expense from "./components/Accounts/Add_Expense";
import Taxes from "./components/Accounts/Taxes";
import Add_Tax from "./components/Accounts/Add_Tax";
import EmployeeSalary from "./components/Payroll/EmployeeSalary/EmployeeSalary";

import Inbox from "./components/Email/Inbox";
import AddLeave from "./components/Staff/AddLeave";
import Attendence from "./components/Staff/Attendence";
import Leave from "./components/Staff/Leave";
import ComposeMail from "./components/Email/ComposeMail";
import MailView from "./components/Email/MailView";
import UserActivity from "./components/Activity/UserActivity";
import AddEmployeeSalary from "./components/Payroll/EmployeeSalary/AddEmployeeSalary";
import Chat from "./components/Chat/Chat";
import VoiceCall from "./components/Call/VoiceCall";
import VideoCall from "./components/Call/VideoCall";
import EditStaff from "./components/Staff/EditStaff";
import EditLeave from "./components/Staff/EditLeave";
import Holiday from "./components/Staff/Holiday";
import Add_ProviderFund from "./components/Accounts/Add_ProviderFund";
import ExpensesReport from "./components/ExpenseReport/Expenses/ExpensesReport";
import AddExpenses from "./components/ExpenseReport/Expenses/AddExpenses";
import Invoice_Report from "./components/ExpenseReport/Invoice-report/Invoice_Report";
import OverDue from "./components/Invoice/Invoice-List/Overdue-Invoice/OverDue";
import InvoiceList from "./components/Invoice/Invoice-List/InvoiceList";
import Paid_Invoice from "./components/Invoice/Invoice-List/Paid-Invoice/Paid_Invoice";
import Draft_Invoice from "./components/Invoice/Invoice-List/Draft_Invoice/Draft_Invoice";
import Recurring_Invoice from "./components/Invoice/Invoice-List/Recurring_Invoice/Recurring_Invoice";
import Cancelled_Invoice from "./components/Invoice/Invoice-List/Cancelled_Invoice/Cancelled_Invoice";
import Invoice_Grid from "./components/Invoice/Invoices_Grid/Invoice_Grid";
import Add_Invoices from "./components/Invoice/Add_Invoices/Add_Invoices";
import Edit_Invoices from "./components/Invoice/Edit_Invoices/Edit_Invoices";
import Invoice_Details from "./components/Invoice/Invoice_Details/Invoice_Details";
import Invoice_GeneralSettings from "./components/Invoice/Invoice_Settings/General_Settings/Invoice_GeneralSettings";
import Tax_Settings from "./components/Invoice/Invoice_Settings/Tax_Settings/Tax_Settings";
import Bank_Settings from "./components/Invoice/Invoice_Settings/Bank_Settings/Bank_Settings";
import IncomingCall from "./components/Chat/IncomingCall";
import BasicInput from "./components/Forms/BasicInput";
import InputGroups from "./components/Forms/InputGroups";
import HorizontalForm from "./components/Forms/HorizontalForm";
import VerticalForm from "./components/Forms/VerticalForm";
import BasicTable from "./components/Tables/BasicTable";
import DataTable from "./components/Tables/DataTable";
import UiKit from "./components/Ui_Elements/UiKit";
import Typography from "./components/Ui_Elements/Typography";
import Tab from "./components/Ui_Elements/Tab";
import Edit_Assets from "./components/Assets/Edit_Assets";
import LockScreen from "./components/Pages/Login/LockScreen";
import ChangePassword from "./components/Pages/Login/ChangePassword";
import Error from "./components/Pages/Login/Error";
import ServerError from "./components/Pages/Login/ServerError";
import EditEmployeeSalery from "./components/Payroll/EmployeeSalary/EditEmployeeSalery";
import Calender from "./components/Calender/Calender";
import Profile from "./components/Pages/Login/Profile";
import EditProfile from "./components/Pages/Login/EditProfile";
import BlankPage from "./components/Pages/Login/BlankPage";
import Terms from "./components/Pages/Terms";
import Privacy from "./components/Pages/Privacy";

import SuperAdmin_Dashboard from "./components/Dashboard/SuperAdmin_Dashboard/SuperAdmin_Dashboard";
import Admin_Dashboard from "./components/Dashboard/Admin_Dashboard/Admin_Dashboard";
import Specialist_Dashboard from "./components/Dashboard/Specialist_Dashboard/Specialist_Dashboard";
import Nurse_Dashboard from "./components/Dashboard/Nurse_Dashboard/Nurse_Dashboard";
import Receptionist_Dashboard from "./components/Dashboard/Receptionist_Dashboard/Receptionist_Dashboard";
import Patient_Dashboard from "./components/Dashboard/Patient_Dashboard/Patient_Dashboard";

//import SuperAdmin_Settings from "./components/Dashboard/SuperAdmin_Dashboard/SuperAdmin_Settings";
//import Admin_Settings from "./components/Dashboard/Admin_Dashboard/Admin_Settings";
import Specialist_Settings from "./components/Dashboard/Specialist_Dashboard/Specialist_Settings";
import Receptionist_Settings from "./components/Dashboard/Receptionist_Dashboard/Receptionist_Settings";
import Patient_Settings from "./components/Patients/Patient_Settings";
import Staff_Settings from "./components/Staff/Staff-Settings";

import Edit_Provident from "./components/Accounts/Edit_Provident";
import Edit_Taxes from "./components/Accounts/Edit_Taxes";
import Edit_Expenses from "./components/ExpenseReport/Expenses/Edit_Expenses";
import Edit_Payment from "./components/Accounts/Edit_Payment";
import Payslip from "./components/Payroll/Payslip";
import Setting from "./components/Settings/Setting";
import GalleryImage from "./components/Pages/Gallery/Gallery";

const Approuter = () => {
  // eslint-disable-next-line no-unused-vars
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/lockscreen" element={<LockScreen />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/error" element={<Error />} />
          <Route path="/server-error" element={<ServerError />} />
          <Route path="/blankpage" element={<BlankPage />} />
          <Route path="/gallery" element={<GalleryImage />} />
          {/* Dashboard */}
          <Route path="/superadmin-dashboard" element={<SuperAdmin_Dashboard />} />
          <Route path="/admin-dashboard" element={<Admin_Dashboard />} />
          <Route path="/specialist-dashboard" element={<Specialist_Dashboard />} />
          <Route path="/nurse-dashboard" element={<Nurse_Dashboard />} />
          <Route path="/receptionist-dashboard" element={<Receptionist_Dashboard />} />
          <Route path="/patient-dashboard" element={<Patient_Dashboard />} />
          {/* LIFE POD */}
          <Route path="/manage-life-pod" element={<ManageLifePod />} />
          <Route path="/life-pod-access" element={<LifePodAccess />} />
          {/* BANNERS */}
          <Route path="/banners" element={<BannerList />} />
          <Route path="/add-banner" element={<AddBanner />} />
          <Route path="/edit-banner" element={<EditBanner />} />
          {/* ARTICLES */}
          <Route path="/article/{uniqueid}" element={<ArticleDetail />} />
          <Route path="/add-article" element={<AddArticle />} />
          <Route path="/edit-article" element={<EditArticle />} />
          <Route path="/articles" element={<Articles />} />
          {/* REELS */}
          <Route path="/reel/{uniqueid}" element={<ReelDetail />} />
          <Route path="/add-reel" element={<AddReel />} />
          <Route path="/edit-reel" element={<EditReel />} />
          <Route path="/reels" element={<Reels />} />
          {/* Settings */}
          <Route path="/settings" element={<Setting />} />
          <Route path="/localization" element={<Localization />} />
          <Route path="/paymentsetting" element={<Paymentsetting />} />
          <Route path="/settingsemail" element={<Settingsemail />} />
          <Route path="/settingssocialmedia" element={<Settingssocialmedia />} />
          <Route path="/settingssociallink" element={<Settingssociallinks />} />
          <Route path="/settingsseo" element={<Settingsseo />} />
          <Route path="/settingsthem" element={<SettingsThem />} />
          <Route path="/settingschangepassword" element={<SettingsChangePassword />} />
          <Route path="/settingsothers" element={<SettingsOthers />} />
          {/* Assets */}
          <Route path="/assets" element={<Assets />} />
          <Route path="/addasset" element={<AddAsset />} />
          <Route path="/edit-assets" element={<Edit_Assets />} />
          {/* Tenants */}
          <Route path="/tenants" element={<TenantList />} />
          <Route path="/add-tenant" element={<AddTenant />} />
          <Route path="/edit-tenant" element={<EditTenant />} />
          <Route path="/tenant-types" element={<TenantTypes />} />
          {/* Clinics */}
          <Route path="/clinics" element={<ClinicList />} />
          <Route path="/add-clinic" element={<AddClinic />} />
          <Route path="/edit-clinic" element={<EditClinic />} />
          {/* Specialists */}
          <Route path="/specialists" element={<SpecialistList />} />
          <Route path="/add-specialist" element={<AddSpecialist />} />
          <Route path="/edit-specialist" element={<EditSpecialist />} />
          <Route path="/specialist-profile" element={<SpecialistProfile />} />
          <Route path="/specialist-setting" element={<Specialist_Settings />} />
          {/* SpecialistSchedule */}
          <Route path="/schedules" element={<ScheduleList />} />
          <Route path="/add-schedule" element={<AddSchedule />} />
          <Route path="/edit-schedule" element={<EditSchedule />} />
          {/* Patients */}
          <Route path="/patients" element={<PatientsList />} />
          <Route path="/add-patient" element={<AddPatients />} />
          <Route path="/edit-patient" element={<EditPatients />} />
          <Route path="/patientsprofile" element={<PatientsProfile />} />
          <Route path="/patient-settings" element={<Patient_Settings />} />
          {/* Appointments */}
          <Route path="/appointments" element={<AppointmentList />} />
          <Route path="/add-appointment" element={<AddAppointment />} />
          <Route path="/edit-appointment" element={<EditAppointment />} />
          {/* Operations */}
          <Route path="/operations" element={<OperationList />} />
          <Route path="/add-operation" element={<AddOperation />} />
          <Route path="/edit-operation" element={<EditOperation />} />
          {/* Department */}
          <Route path="/departments" element={<DepartmentList />} />
          <Route path="/add-department" element={<AddDepartment />} />
          <Route path="/edit-department" element={<EditDepartment />} />
          {/* Staff */}
          <Route path="/staff" element={<StaffList />} />
          <Route path="/add-staff" element={<AddStaff />} />
          <Route path="/edit-staff" element={<EditStaff />} />
          <Route path="/staff-profile" element={<StaffProfile />} />
          <Route path="/leave" element={<Leave />} />
          <Route path="/add-leave" element={<AddLeave />} />
          <Route path="/edit-leave" element={<EditLeave />} />
          <Route path="/attendence" element={<Attendence />} />
          <Route path="/holiday" element={<Holiday />} />
          <Route path="/staff-settings" element={<Staff_Settings />} />
          {/* Accounts */}
          <Route path="/providentfund" element={<ProvidentFund />} />
          <Route path="/add-providerfund" element={<Add_ProviderFund />} />
          <Route path="/invoices" element={<Invoice />} />
          <Route path="/create-invoice" element={<Create_Invoice />} />
          <Route path="/payments" element={<Payments />} />
          <Route path="/add-payment" element={<Add_Payment />} />
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/add-expense" element={<Add_Expense />} />
          <Route path="/taxes" element={<Taxes />} />
          <Route path="/edit-taxes" element={<Edit_Taxes />} />
          <Route path="/addtax" element={<Add_Tax />} />
          <Route path="/edit-provident" element={<Edit_Provident />} />
          <Route path="/edit-payment" element={<Edit_Payment />} />
          {/* /* Payroll */}
          <Route path="/employee-salary" element={<EmployeeSalary />} />
          <Route path="/add-salary" element={<AddEmployeeSalary />} />
          <Route path="/edit-salary" element={<EditEmployeeSalery />} />
          <Route path="/payslip" element={<Payslip />} />
          {/* Email */}
          <Route path="/inbox" element={<Inbox />} />
          <Route path="/compose-mail" element={<ComposeMail />} />
          <Route path="/mail-view" element={<MailView />} />
          {/* Activity */}
          <Route path="/user-activity" element={<UserActivity />} />
          {/* ExpenseReport */}
          <Route path="/expense-Report" element={<ExpensesReport />} />
          <Route path="/add-expense" element={<AddExpenses />} />
          <Route path="/invoice-report" element={<Invoice_Report />} />
          <Route path="/edit-expenses" element={<Edit_Expenses />} />
          {/* Chat */}
          <Route path="/chat" element={<Chat />} />
          {/* Call */}
          <Route path="/voice-call" element={<VoiceCall />} />
          <Route path="/video-call" element={<VideoCall />} />
          <Route path="/incoming-call" element={<IncomingCall />} />
          {/* Invoice */}
          <Route path="/invoice-list" element={<InvoiceList />} />
          <Route path="/paid-invoice" element={<Paid_Invoice />} />
          <Route path="/overdue-invoice" element={<OverDue />} />
          <Route path="/draft-invoice" element={<Draft_Invoice />} />
          <Route path="/recurring-invoice" element={<Recurring_Invoice />} />
          <Route path="/cancelled-invoice" element={<Cancelled_Invoice />} />
          <Route path="/invoice-grid" element={<Invoice_Grid />} />
          <Route path="/add-invoice" element={<Add_Invoices />} />
          <Route path="/edit-invoice" element={<Edit_Invoices />} />
          <Route path="/invoice-details" element={<Invoice_Details />} />
          <Route path="/invoice-settings" element={<Invoice_GeneralSettings />} />
          <Route path="/tax-settings" element={<Tax_Settings />} />
          <Route path="/bank-settings" element={<Bank_Settings />} />
          {/* ui-elements */}
          <Route path="/ui-kit" element={<UiKit />} />
          <Route path="/typography" element={<Typography />} />
          <Route path="/tab" element={<Tab />} />
          {/* Forms */}
          <Route path="/basic-input" element={<BasicInput />} />
          <Route path="/inputgroup" element={<InputGroups />} />
          <Route path="/horizontal-form" element={<HorizontalForm />} />
          <Route path="/vertical-form" element={<VerticalForm />} />
          {/* Tables */}
          <Route path="/basic-table" element={<BasicTable />} />
          <Route path="/data-table" element={<DataTable />} />
          {/* Calender */}
          <Route path="/calender" element={<Calender />} />
        </Routes>
      </BrowserRouter>
      <div className="sidebar-overlay"></div>
    </>
  );
};

export default Approuter;
