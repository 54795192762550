/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import { blog, dashboard, specialist, specialistschedule, logout, menuicon04, menuicon06, menuicon08, menuicon09, menuicon10, menuicon11, menuicon12, menuicon14, menuicon15, menuicon16, patients, sidemenu } from '../imagepath';
import Scrollbars from "react-custom-scrollbars-2";

const PatientMenu = (props) => {
  const [sidebar, setSidebar] = useState("");
  const handleClick = (e, item, item1, item3) => {
    const div = document.querySelector(`#${item}`);
    const ulDiv = document.querySelector(`.${item1}`);
    e?.target?.className ? ulDiv.style.display = 'none' : ulDiv.style.display = 'block'
    e?.target?.className ? div.classList.remove('subdrop') : div.classList.add('subdrop');
  }

  useEffect(() => {
    if (props?.id && props?.id1) {
      const ele = document.getElementById(`${props?.id}`);
      handleClick(ele, props?.id, props?.id1);
    }
  }, [])

  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };

  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };

  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}>
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu"
              onMouseLeave={expandMenu}
              onMouseOver={expandMenuOpen}>
              <ul>
                <li className="menu-title">Main</li>
                <li>
                  <Link className={props?.activeClassName === 'dashboard' ? 'active' : ''} to="/patient-dashboard">
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>{" "}
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item1" onClick={(e) => handleClick(e, "menu-item1", "menu-items1")}>
                    <span className="menu-side">
                      <img src={menuicon09} alt="" />
                    </span>{" "}
                    <span> LifePod</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items1">
                    <li>
                      <Link className={props?.activeClassName === 'manage-life-pod' ? 'active' : ''} to="/manage-life-pod">Manage Content</Link>
                    </li>
                    <li >
                      <Link className={props?.activeClassName === 'health-pod-access' ? 'active' : ''} to="/health-pod-access">Manage Access</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'dashboard' ? 'active' : ''} to="/specialists">
                    <span className="menu-side">
                      <img src={specialist} alt="" />
                    </span>{" "}
                    <span>Specialists</span>
                  </Link>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'dashboard' ? 'active' : ''} to="/appointments">
                    <span className="menu-side">
                      <img src={menuicon04} alt="" />
                    </span>{" "}
                    <span>My Appointments</span>
                  </Link>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'chat' ? 'active' : ''} to="/chat">
                    <span className="menu-side">
                      <img src={menuicon10} alt="" />
                    </span>{" "}
                    <span>Chat</span>
                  </Link>
                </li>
                <li className="submenu">
                  <Link to="#" id="menu-item9" onClick={(e) => handleClick(e, "menu-item9", "menu-items9")}>
                    <span className="menu-side">
                      <img src={menuicon11} alt="" />
                    </span>{" "}
                    <span> Calls</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items9">
                    <li>
                      <Link className={props?.activeClassName === 'voice-call' ? 'active' : ''} to="/voice-call">Voice Call</Link>
                    </li>
                    <li >
                      <Link className={props?.activeClassName === 'video-call' ? 'active' : ''} to="/video-call">Video Call</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'incoming-call' ? 'active' : ''} to="/incoming-call">Incoming Call</Link>
                    </li>
                  </ul>
                </li>
                {/*<li className="submenu">
                  <Link to="#" id="menu-item10" onClick={(e) => handleClick(e, "menu-item10", "menu-items10")}>
                    <span className="menu-side">
                      <img src={menuicon12} alt="" />
                    </span>{" "}
                    <span> Email</span> <span className="menu-arrow" />
                  </Link>
                  <ul style={{ display: "none" }} className="menu-items10">
                    <li>
                      <Link className={props?.activeClassName === 'compose-mail' ? 'active' : ''} to="/compose-mail">Compose Mail</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'inbox' ? 'active' : ''} to="/inbox">Inbox</Link>
                    </li>
                    <li>
                      <Link className={props?.activeClassName === 'mail-view' ? 'active' : ''} to="/mail-view">Mail View</Link>
                    </li>
                  </ul>
                </li>*/}
                <li>
                  <Link className={props?.activeClassName === 'articles' ? 'active' : ''} to="/articles">
                    <span className="menu-side">
                      <img src={blog} alt="" />
                    </span>{" "}
                    <span>News</span>
                  </Link>
                </li>
                <li>
                  <Link className={props?.activeClassName === 'invoices' ? 'active' : ''} to="/invoice-list">
                    <span className="menu-side">
                      <img src={menuicon15} alt="" />
                    </span>{" "}
                    <span>Invoices</span>
                  </Link>
                </li>
                <li>
                  <Link to="/settings">
                    <span className="menu-side">
                      <img src={menuicon16} alt="" />
                    </span>{" "}
                    <span>Settings</span>
                  </Link>
                </li>
              </ul>
              <div className="logout-btn">
                <Link to="/login">
                  <span className="menu-side">
                    <img src={logout} alt="" />
                  </span>{" "}
                  <span>Logout</span>
                </Link>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  )
}

export default PatientMenu
